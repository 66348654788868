<template>
  <el-dialog
    title="系统说明"
    :visible.sync="visible"
    width="850px"
    top="20vh"
    :before-close="closeOpen"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
  >
    <div class="contentbox">
      <p v-for="(item, index) in info" :key="index">{{ item }}</p>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      fileList: [],
      pageParams: {
        size: 100,
        current: 1
      },
      info: [
        '1. 点击ctrl+鼠标滚轮缩放画布大小。',
        '2.在画布中的工具左击可唤起对其进行编辑状态，以该点的对角为中心点对其进行拉拽调整大小',
        '3.在画布上右击图标可进行复制、粘贴、删除、调整图层顺序（置于顶层、置于底层、上移一层、下移一层）',
        '4.画布上单击长按鼠标左键可以拖动组件并改变其位置。选中元件点击上下左右箭头可移动位置。',
        '5.在画布上鼠标左击可调取该工具的属性面板',
        '6.在画布上常用工具、设备、附属设备、其他设备跟管道有个自动吸附功能，自动吸附到距离最近的管道上。',
        '7.键盘向上向下向左向右箭头可移动选中元件。Ctrl+c复制当前选中元件，delete可以删除当前选中元件。',
        '8.Ctrl+箭头可进行调整图层顺序.Ctrl+→置于顶层；Ctrl+←置于顶层；Ctrl+↑上移一层；Ctrl+↓ 下移一层。',
        '9.元件均点击出现编辑状态，支持拖拽调节形状与大小',
        '10.在新建时该文件需要关联场站及创建文件名称。关联场站后后期的采集点及对应的远控阀门选取均为该场站下数据。',
        '11.元件拖动到画布中启用元件，离开画布取消本次操作。',
        '12.ctrl+Z，退回',
        '13.一个场站只支持创建一个工艺图。',
        '14.支持隐藏采集点',
        '13.支持切换主题色'
      ],
      total: 0,
      visible: false
    }
  },
  mounted() {},
  methods: {
    /**
     * 打开dialog
     */
    open() {
      this.visible = true
    },
    /**
     * 关闭dialog
     */
    closeOpen() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
:deep() {
  .el-pagination {
    text-align: center;
  }
}
.formbox {
  margin: 0 15px;
  form {
    padding-bottom: 15px;
  }
  .dialog-footer {
    text-align: right;
  }
  .closeBtn {
    color: #b6b6b6;
    margin-right: 10px;
  }
}
.contentbox {
  p {
    line-height: 24px;
  }
}
</style>
