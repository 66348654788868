<template>
  <div class="title">
    <div class="titleleft"><span
        :title="fileData.configureName"
        >{{ fileData.configureName }}</span
      >
    </div>
    <div class="titleMidle">
      <div class="item" @click="buildNewFile(false)">
        <img :src="require('@/assets/image/svg/addFile.png')" alt="" />
        <span>新建</span>
      </div>
      <div class="item" @click="openFile(false)">
        <img :src="require('@/assets/image/svg/openFile.png')" alt="" />
        <span>打开</span>
      </div>
      <div class="item" @click="saveFile">
        <img :src="require('@/assets/image/svg/saveFile.png')" alt="" />
        <span>保存</span>
      </div>
      <!-- <div class="item" @click="publishFile">
        <img :src="require('@/assets/image/svg/fb.png')" alt="" />
        <span>发布</span>
      </div> -->

      <el-popover v-model="exp" placement="bottom" width="70" trigger="hover">
        <div class="exp">
          <span @click="downImg('png')"><i class="el-icon-download"></i>导出图片</span>
          <span @click="downImg('SVG')"><i class="el-icon-download"></i>导出SVG</span>
          <span @click="downImg('JSON')"><i class="el-icon-download"></i>导出JSON</span>
        </div>

        <div slot="reference" class="item">
          <img :src="require('@/assets/image/svg/exportFile.png')" alt="" />
          <span>导出</span>
        </div>
      </el-popover>

      <div class="item" @click="previewFile">
        <img :src="require('@/assets/image/svg/previewFile.png')" alt="" />
        <span>预览</span>
      </div>

      <div class="item">
        <p>丨</p>
      </div>
      <div
        class="item width50"
        type="select"
        :style="topDisable ? { cursor: 'no-drop', opacity: 0.3 } : ''"
        @click="middleTool('putOnTop', topDisable)"
      >
        <img :src="require('@/assets/image/svg/topFile.png')" alt="" />
        <span>置于顶层</span>
      </div>
      <div
        class="item width50"
        :style="bottomDisable ? { cursor: 'no-drop', opacity: 0.3 } : ''"
        @click="middleTool('putOnButtom', bottomDisable)"
      >
        <img :src="require('@/assets/image/svg/bottomFile.png')" alt="" />
        <span>置于底层</span>
      </div>
      <div
        class="item width50"
        :style="frontDisable ? { cursor: 'no-drop', opacity: 0.3 } : ''"
        @click="middleTool('putOnUp', frontDisable)"
      >
        <img :src="require('@/assets/image/svg/frontWardFile.png')" alt="" />
        <span>上移一层</span>
      </div>
      <div
        class="item width50"
        :style="backDisable ? { cursor: 'no-drop', opacity: 0.3 } : ''"
        @click="middleTool('putOnDown', backDisable)"
      >
        <img :src="require('@/assets/image/svg/backWardFile.png')" alt="" />
        <span>下移一层</span>
      </div>
      <div class="item">
        <p>丨</p>
      </div>
      <div
        class="item"
        :style="copyDisable ? { cursor: 'no-drop', opacity: 0.3 } : ''"
        @click="middleTool('copy', copyDisable)"
      >
        <img :src="require('@/assets/image/svg/copyFile.png')" alt="" />
        <span>复制</span>
      </div>
      <!-- <div class="item" @click="pasteFile">
        <img :src="require('@/assets/image/svg/pasteFile.png')" alt="" />
        <span>粘贴</span>
      </div> -->
      <div
        class="item"
        :style="clearDisable ? { cursor: 'no-drop', opacity: 0.3 } : ''"
        @click="middleTool('delete', clearDisable)"
      >
        <img :src="require('@/assets/image/svg/clearFile.png')" alt="" />
        <span>删除</span>
      </div>

      <div class="item">
        <p>丨</p>
      </div>

      <div
        v-for="item in alignOption"
        :key="item.value"
        class="item width50"
        type="select"
        :style="alignDisable ? { cursor: 'no-drop', opacity: 0.3 } : ''"
        @click="alignFun(item.value)"
      >
        <img :src="item.img" alt="" />
        <span>{{ item.name }}</span>
      </div>
      <div class="item">
        <p>丨</p>
      </div>
      <div class="item width50" @click="sysInfo">
        <img :src="require('@/assets/image/svg/help.png')" alt="" />
        <span>系统说明</span>
      </div>
    </div>
    <div class="titleRight">
      <div
        :class="selectColor == 'white' ? 'selectColor selectColorShow' : 'selectColor'"
        title="浅色主题"
        @click="changeColor('white')"
      ></div>
      <div
        :class="selectColor == 'black' ? 'selectColor selectColorShow' : 'selectColor'"
        title="深色主题"
        @click="changeColor('black')"
      ></div>
      <div
        :class="selectColor == 'other' ? 'selectColor selectColorShow' : 'selectColor'"
        :style="'background:' + bgColor"
        title="自定义主题"
        @click="changeColor('other')"
      ></div>
      <el-color-picker
        v-model="bgColor"
        color-format="hex"
        title="选择自定义颜色"
        @change="changeBgColor"
      ></el-color-picker>
      <div class="item">
        <p>丨</p>
      </div>
      <div class="userName" :title="user.name">
        <el-popover trigger="hover" placement="bottom-start">
          <div class="out" @click="loginOUt">退出</div>
          <span slot="reference">{{ user.name }}<i class="el-icon-arrow-down"></i></span>
        </el-popover>
      </div>
    </div>
    <build-new-file ref="buildNewFile"></build-new-file>
    <open-file ref="openFile"></open-file>
    <system-description ref="sysInfo"></system-description>
    <preview ref="preview"></preview>
  </div>
</template>
<script>
import buildNewFile from './components/buildNewFile.vue'
import preview from './components/preview.vue'
import openFile from './components/openFile.vue'
import systemDescription from './components/systemDescription.vue'
import { GenUUidByType } from '@/utils/UCore.js'
// import { updateConfigure, updateForApply, uploadImg } from '@/apis/svg'
import scadaApi from "@/apis/scadaApi";
import contractApi from "@/apis/contractApi";
// import { getUser, getLoginUrl, setUser, setToken } from '@/utils/auth'
// import { getLoginOut } from '@/apis/user'

export default {
  components: { buildNewFile, openFile, systemDescription, preview },
  data() {
    return {
      bgColor: '',
      exp: false,
      fileData: {
        configureName: '',
        id: '',
        stationId: ''
      },
      selectColor: 'white',
      topDisable: true,
      bottomDisable: true,
      frontDisable: true,
      backDisable: true,
      copyDisable: true,
      clearDisable: true,
      copySvgInfo: {},
      alignDisable: true,
      alignOption: [
        { name: '上对齐', value: 'top', img: require('@/assets/image/svg/top.png') },
        { name: '下对齐', value: 'bottom', img: require('@/assets/image/svg/bottom.png') },
        { name: '左对齐', value: 'left', img: require('@/assets/image/svg/left.png') },
        { name: '右对齐', value: 'right', img: require('@/assets/image/svg/right.png') },
        {
          name: '水平居中',
          value: 'horizontally',
          img: require('@/assets/image/svg/horizontally.png')
        },
        { name: '垂直居中', value: 'vertically', img: require('@/assets/image/svg/vertically.png') }
      ],
      user: {}
    }
  },
  mounted() {
    // 第一次进入 必须选择或者新建
    let fileObj = localStorage.getItem('fileObjZL')
    if (fileObj) {
      this.fileData = JSON.parse(fileObj)
    }
    // if(!this.fileData,id){
    this.$refs.openFile.open(true)
    //  }
    this.$eventBus.$on('selectChange', data => {
      this.copyDisable = data
      this.clearDisable = data
    })
    this.$eventBus.$on('drawChange', data => {
      let svgLists = data[0]
      let selectSvg = data[1]
      const _this = this
      _this.resetBtn()
      if (svgLists && svgLists.length && selectSvg) {
        //判断当前选中组件的index
        if (svgLists.length === 1) {
          //禁用下移 禁用上移
          _this.copyDisable = false
          _this.clearDisable = false
        } else if (selectSvg.Index === 0) {
          //禁用下移
          _this.copyDisable = false
          _this.clearDisable = false
          _this.topDisable = false
          _this.frontDisable = false
        } else if (selectSvg.Index === svgLists.length - 1) {
          //禁用上移
          _this.copyDisable = false
          _this.clearDisable = false
          _this.bottomDisable = false
          _this.backDisable = false
        } else {
          _this.topDisable = false
          _this.bottomDisable = false
          _this.frontDisable = false
          _this.backDisable = false
          _this.copyDisable = false
          _this.clearDisable = false
        }
      }
    })
  },
  methods: {
    changeBgColor(e) {
      if (this.selectColor == 'other') {
        this.$parent.$refs.baseSvg.showBgColor = e
      }
    },
    loginOUt() {
      getLoginOut({}).then(res => {
        setUser('')
        setToken('')
        localStorage.setItem('menutreeM', '')
        // if (getLoginUrl()) {
        //   window.location.href = getLoginUrl()
        // } else {
        //   this.$router.push('/login').catch(err => {})
        // }
        // 2023/6/25 退出跳转门户登录
        this.$router.push({ path: '/login' })
      })
    },
    // 更改主题颜色
    changeColor(color) {
      let that = this
      if (color == 'black') {
        this.selectColor = color
        document.body.classList.add('black')
        this.$parent.box.background = '0b1524'
        this.$parent.$refs.baseSvg.showBgColor = '#0b1524'
      } else if (color == 'other') {
        if (that.bgColor) {
          that.selectColor = color
          that.$parent.$refs.baseSvg.showBgColor = that.bgColor
        } else {
          that.$message.warning('请先选择自定义颜色')
        }
      } else {
        this.selectColor = color
        document.body.classList.remove('black')
        this.$parent.box.background = 'fff'
        that.$parent.$refs.baseSvg.showBgColor = '#fff'
      }
    },
    // 导出
    downImg(type) {
      this.$parent.$refs.baseSvg.converDownload(type, this.fileData.configureName)
      this.exp = false
    },
    /**
     * 功能描述：新建组态图
     * 开发人：刘政杰
     * 开发时间：2022/8/25
     * 重点参数：
     */
    buildNewFile(type) {
      let svgLists = this.$parent.$refs.baseSvg.svgLists
      if (svgLists && svgLists.length) {
        this.$confirm('新建文件会清空当前绘制，是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$refs.buildNewFile.open(type)
          })
          .catch(() => {})
      } else {
        this.$refs.buildNewFile.open(type)
      }
    },
    // 打开
    openFile(type) {
      let svgLists = this.$parent.$refs.baseSvg.svgLists
      if (svgLists && svgLists.length) {
        this.$confirm('打开新文件会清空当前绘制，是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$refs.openFile.open(type)
          })
          .catch(() => {})
      } else {
        this.$refs.openFile.open(type)
      }
    },
    // 保存
    saveFile() {
      // 未绘制和未创建项目无法保存
      let svgLists = this.$parent.$refs.baseSvg.svgLists
      if (svgLists && svgLists.length == 0) return this.$message.error('请先绘制')
      if (!this.fileData.id) return this.$message.error('请先创建或导入项目')
      let zoom = this.$parent.$refs.baseSvg.stationObj.zoom
      let width = this.$parent.box.width
      let height = this.$parent.box.height
      let background = this.$parent.box.background
      let fileObj = {
        svgLists,
        zoom,
        width,
        height,
        background,
        legendx: this.$parent.$refs.baseSvg.stationObj.legendx,
        legendy: this.$parent.$refs.baseSvg.stationObj.legendy,
        other: this.bgColor
      }
      // 保存
      let obj = {
        id: this.fileData.id,
        configureInfo: JSON.stringify(fileObj)
      }
      scadaApi.updateConfigure(obj).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
        } else {
          this.$message.err(res.msg)
        }
      })
    },
    publishFile() {
      let that = this
      let str = '确认要将 ' + that.fileData.configureName + '工艺图发布？'
      // 删除配置
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let svgLists = that.$parent.$refs.baseSvg.svgLists
          if (svgLists && svgLists.length == 0) return that.$message.error('请先绘制')
          if (!that.fileData.id) return that.$message.error('请先创建或导入项目')
          let zoom = that.$parent.$refs.baseSvg.stationObj.zoom
          let width = that.$parent.box.width
          let height = that.$parent.box.height
          let background = that.$parent.box.background
          let fileObj = {
            svgLists,
            zoom,
            width,
            height,
            background,
            legendx: that.$parent.$refs.baseSvg.stationObj.legendx,
            legendy: that.$parent.$refs.baseSvg.stationObj.legendy,
            other: this.bgColor
          }
          let obj = {
            id: that.fileData.id,
            configureInfo: JSON.stringify(fileObj),
            applyStatus: 1
          }
          // 发布前转换图片文件
          var mainWarp = document.getElementById('svgMain')
          var serializer = new XMLSerializer()
          var source = serializer.serializeToString(mainWarp)
          var path =
            'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(source)))
          var image = new Image()
          image.src = path
          let file
          image.onload = function() {
            //这样就获取到了⽂件的Base64字符串
            var base64 = that.getBase64Image(image)
            //Base64字符串转⼆进制
            file = that.dataURLtoFile(base64, '工艺图.png')
            let repairBeforFileFormData = new FormData()
            repairBeforFileFormData.append('file', file)
            repairBeforFileFormData.append('modelName', '测试')
            contractApi.uploadImg(repairBeforFileFormData).then(res => {
              if (res.code == 200) {
                obj.configureImg = res.data.url
                scadaApi.updateForApply(obj).then(res => {
                  if (res.code == 200) {
                    that.$message.success(that.fileData.configureName + '工艺图发布完成！')
                  } else {
                    that.$message.err(res.msg)
                  }
                })
              } else {
                that.$message.error(res.msg)
              }
            })
          }
        })
        .catch(() => {})
    },
    /**
     * ⽹络图像⽂件转Base64
     */
    getBase64Image(img) {
      var canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, img.width, img.height)
      var ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
      var dataURL = canvas.toDataURL('image/' + ext)
      return dataURL
    },
    /**
     *Base64字符串转⼆进制
     */
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], {
        type: mime
      })
    },
    //将base64转换为文件
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 导出
    exportFile() {},
    // 预览
    previewFile() {
      this.$refs.preview.open()
    },
    // 操作汇总
    middleTool(type, flag) {
      const _this = this
      if (!flag) {
        let obj = { enable: true, type: type }
        _this.$parent.$refs.baseSvg.contextmenuSelect(obj)
      }
    },
    alignFun(val) {
      this.$parent.$refs.baseSvg.alignMenu(val)
    },
    // 复制
    copyFile() {
      let copySvgInfo = JSON.parse(JSON.stringify(this.$parent.selectSvgInfo))
      this.copySvgInfo = copySvgInfo
    },
    // 粘贴
    pasteFile() {
      const _this = this
      if (_this.copySvgInfo.id) {
        let svg_id = GenUUidByType(_this.$store.getters.CurrentlySelectedToolBar.Identity, 8, 16)
        _this.copySvgInfo.id = svg_id
        if (_this.copySvgInfo.extend_attr.color_id) {
          //渐变颜色id
          _this.copySvgInfo.extend_attr.color_id.val = 'cid' + svg_id
        }
        _this.$parent.$refs.baseSvg.svgLists.push(_this.copySvgInfo)
        setTimeout(() => {
          _this.copySvgInfo = {}
        }, 100)
      }
    },
    // 删除
    clearFile() {
      // 根据selectSvgInfo的id查出所在svgLists的index并删除
      const _this = this
      let selectSvgInfo = _this.$parent.selectSvgInfo
      let svgLists = _this.$parent.$refs.baseSvg.svgLists
      let index = ''
      svgLists.forEach((item, indexs) => {
        if (item.id == selectSvgInfo.id) {
          index = indexs
        }
      })
      if (index !== '') {
        svgLists.splice(index, 1)
        _this.$parent.selectSvgInfo = {}
        _this.$parent.$refs.baseSvg.deleteEit()
      }
    },
    // 系统说明
    sysInfo() {
      this.$refs.sysInfo.open()
    },
    resetBtn() {
      this.topDisable = true
      this.bottomDisable = true
      this.frontDisable = true
      this.backDisable = true
      this.copyDisable = true
      this.clearDisable = true
    }
  }
}
</script>
<style lang="scss" scoped>
.out {
  cursor: pointer;
  padding: 10px 20px;
}
.exp {
  span {
    width: 100%;
    padding: 5px 0px;
    display: block;
    border-bottom: 1px solid #e9e9e9;
    cursor: pointer;
    i {
      padding-right: 5px;
    }
  }
}
.title {
  display: flex;
  background-color: var(--background-top-color-skin);
  color: var(--background-top-font-skin);
  .titleleft {
    display: flex;
    width: 20%;
    height: 54px;
    align-items: center;
    justify-content: left;
    padding-left: 22px;
    font-size: 22px;
    font-weight: bolder;
    img {
      width: 34px;
      margin-right: 10px;
    }
    span {
      display: block;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;
      line-height: 34px;
    }
  }
  .titleMidle {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 80%;
    margin: 10px;
    .item {
      margin: 0 8px;
      width: 24px;
      display: grid;
      justify-items: center;
      cursor: pointer;
      img {
        height: 16px;
        justify-content: center;
        filter: var(--background-top-filter-skin);
      }
      span {
        font-size: 12px;
        margin-top: 4px;
      }
      p {
        font-size: 25px;
        color: var(--background-top-border);
        font-weight: 100;
      }
    }
    .width50 {
      width: 50px;
    }
  }
  .titleRight {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 20%;
    margin: 10px;
    .selectColor {
      display: inline-block;
      width: 18px;
      height: 18px;
      border: 2px solid #4a4a4a;
      cursor: pointer;
      margin-right: 8px;
    }
    .selectColor:nth-of-type(1) {
      background: #fff;
    }
    .selectColor:nth-of-type(2) {
      background: #0b1524;
    }
    .selectColor.selectColorShow {
      border: 2px solid #2680eb;
      box-shadow: 0px 0px 5px #2680eb;
    }
    .item {
      margin: 0 18px;
      display: grid;
      justify-items: center;
      cursor: pointer;
      img {
        width: 16px;
        justify-content: center;
      }
      span {
        font-size: 12px;
      }
      p {
        font-size: 25px;
        color: var(--background-top-border);
        font-weight: 100;
      }
    }
    .userName {
      line-height: 34px;
      max-width: calc(100% - 200px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: right;
    }
  }
}
</style>
