<template>
  <div>
    <el-dialog
      title="项目列表"
      :visible.sync="visible"
      width="850px"
      top="20vh"
      :before-close="closeOpen"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
    >
      <!-- :show-close="$parent.fileData.id ? true : false" -->
      <div class="contentbox">
        <el-button v-if="showBtn" type="primary" @click="openNewFile">新建</el-button>
        <el-table
          ref="fileData"
          :data="fileList"
          style="width: 100%;margin: 15px 0;"
          :stripe="true"
          :show-header="false"
          height="400"
        >
          <el-table-column prop="configureName" label="文件名" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <i class="el-icon-document"> {{ row.configureName }} </i>
            </template>
          </el-table-column>
          <el-table-column
            prop="stationName"
            label="场站名"
            width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="时间"
            align="center"
            width="160"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="prop" label="操作" align="center" width="240">
            <template slot-scope="{ row }">
              <el-button plain type="primary" size="mini" @click="selectFile(row)">打开</el-button>
              <el-button plain type="primary" size="mini" @click="editName(row)">编辑</el-button>
              <el-button plain type="danger" size="mini" @click="delFile(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            :current-page="pageParams.current"
            :background="true"
            :page-sizes="[10, 30, 50, 100]"
            :page-size="pageParams.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="编辑文件名称"
      :visible.sync="editViside"
      width="550px"
      top="30vh"
      :before-close="closeEdit"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
    >
      <el-form
        ref="ruleForm"
        :model="editForm"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="文件名称" prop="configureName">
          <el-input v-model="editForm.configureName"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="closeBtn" @click="editViside = false">取 消</el-button>
        <el-button type="primary" @click="editSave">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import scadaApi from "@/apis/scadaApi";
export default {
  props: {},
  data() {
    return {
      fileList: [],
      pageParams: {
        size: 100,
        current: 1
      },
      editForm: {
        id: '',
        configureName: ''
      },
      total: 0,
      visible: false,
      showBtn: false,
      editViside: false,
      rules: {
        configureName: [
          { required: true, message: '请输入文件名称', trigger: 'blur' },
          { max: 20, message: '最多输入20个字符', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    // 请求项目列表
  },
  methods: {
    /**
     * 打开dialog
     */
    open(type) {
      if (type) {
        this.showBtn = true
      } else {
        this.showBtn = false
      }
      this.visible = true
      this.pageParams.size = 100
      this.pageParams.current = 1
      this.loadList()
    },
    loadList() {
      let obj = { userId: '' }
      scadaApi.ztUserHistoryInfo(this.pageParams).then(res => {
        if (res.code == 200) {
          this.fileList = res.data.list
          this.total = res.data.total
        }
      })
    },
    // 编辑文件名
    editName(row) {
      let data = JSON.parse(JSON.stringify(row))
      this.editForm.id = data.id
      this.editForm.configureName = row.configureName
      this.editViside = true
    },
    /**
     * 关闭dialog
     */
    closeOpen() {
      this.fileList = []
      this.visible = false
      // window.opener=null;
      // window.open('','_self');
      // window.close();
    },
    closeEdit() {
      this.editForm = {
        id: '',
        configureName: ''
      }
      this.editViside = false
    },
    // 新建文件
    openNewFile() {
      this.$parent.buildNewFile(true)
    },
    // 修改名称
    editSave() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          console.log(this.editForm)
          scadaApi.updateConfigure(this.editForm).then(res => {
            if (res.code == 200) {
              this.$message.success('修改成功')
              this.editViside = false
              this.loadList()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    selectFile(row) {
      scadaApi.findById({ id: row.id }).then(res => {
        if (res.code == 200) {
          let data = res.data
          let obj = {
            configureName: data.configureName,
            id: data.id,
            stationId: data.stationId
          }
          this.$parent.fileData = obj
          let fileObj = {
            id: data.id,
            configureName: data.configureName,
            stationId: data.stationId
          }
          localStorage.setItem('fileObjZL', JSON.stringify(fileObj))
          //   this.$parent.$parent.$refs.baseSvg.stationObj.legendx,
          // legendy:this.$parent.$refs.baseSvg.stationObj.legendy,
          // let info=JSON.parse(data.configureInfo)
          //  this.$parent.$parent.$refs.baseSvg.svgLists = info.svgLists
          // data.configureInfoNew = 
          this.$parent.$parent.$refs.baseSvg.openOrbulid(data.configureInfoNew, data.stationId)
          this.closeOpen()
        } else {
          this.$message.error(res.msg)
        }
      })
      // this.$parent.fileCode = row.id
      // this.closeOpen()
    },
    delFile(row) {
      console.log(row)
      let str = '确认删除 ' + row.configureName + '(' + row.stationName + ') 配置信息？'
      // 删除配置
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          console.log(123)
          scadaApi.deleteConfig({ ids: row.id }).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.$message.success('删除成功')
              this.loadList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    handleSizeChange(e) {
      this.pageParams.size = e
      this.loadList()
    },
    handleCurrentChange(e) {
      this.pageParams.current = e
      this.loadList()
    }
  }
}
</script>

<style lang="scss" scoped>
:deep() {
  .el-pagination {
    text-align: center;
  }
  .el-input__inner {
    color: #606266 !important;
    background-color: #fff !important;
    border: 1px solid #dcdfe6 !important;
  }
}
.formbox {
  margin: 0 15px;
  form {
    padding-bottom: 15px;
  }
  .dialog-footer {
    text-align: right;
  }
  .closeBtn {
    color: #b6b6b6;
    margin-right: 10px;
  }
}
</style>
