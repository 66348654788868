<template>
  <el-dialog
    title="新建"
    :visible.sync="visible"
    width="600px"
    top="20vh"
    :before-close="closeOpen"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="!showBtn"
  >
    <div class="contentbox">
      <div class="formbox">
        <el-form
          ref="addform"
          :rules="rules"
          :model="form"
          label-position="top"
          label-width="100px"
        >
          <el-form-item label="文件名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="场站名称" prop="station">
            <el-select
              v-model="form.station"
              placeholder="请选择"
              style="width:100%"
              @change="changeStation"
            >
              <el-option
                v-for="(item, index) in stationList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="场站编码">
            <el-input
              :value="stationDetailInfo.stationCode"
              disabled
              placeholder="请选择场站"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="场站所属公司">
            <el-input
              :value="stationDetailInfo.companyName"
              disabled
              placeholder="请选择场站"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="closeBtn" @click="closeOpen">取 消</el-button>
          <el-button type="primary" @click="saveadd">确 定</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import scadaApi from "@/apis/scadaApi";
// import { getUser } from '@/utils/auth'
export default {
  props: {},
  data() {
    return {
      form: {
        station: '',
        name: ''
      },
      visible: false,
      rules: {
        name: [
          { required: true, message: '请输入文件名', trigger: ['blur', 'change'] },
          { max: 20, message: '最多输入20个字符', trigger: ['blur', 'change'] }
        ],
        station: { required: true, message: '请选择场站', trigger: ['blur', 'change'] }
      },
      stationList: [],
      propsList: [],
      user: {},
      stationDetailInfo: {},
      showBtn: false,
      userId:''
    }
  },
  mounted() {
    this.userId = localStorage.getItem('userId')
    console.log(localStorage.getItem('userId'))
  },
  methods: {
    /**
     * 打开dialog
     */
    open(type) {
      if (type) {
        this.showBtn = true
      } else {
        this.showBtn = false
      }
      this.visible = true
      scadaApi.getStationOptions().then(res => {
        if (res.code == 200) {
          this.stationList = res.data
        }
      })
    },
    changeStation(e) {
      console.log(e)
      // scadaApi.getStationDetail({ stationId: e }).then(res => {
      //   if (res.code == 200) {
      //     let data = res.data[0]
      //     this.stationDetailInfo = data
      //   }
      // })
      this.stationList.forEach(item => {
        if(item.value == e){
          this.stationDetailInfo = item
          console.log(item)
        }
      })
    },
    /**
     * 关闭dialog
     */
    closeOpen() {
      this.form = {
        station: '',
        name: ''
      }
      this.stationDetailInfo = {}
      this.user = {}
      this.$refs.addform.resetFields()
      this.visible = false
    },
    /**
     * 关闭dialog
     */
    saveadd() {
      const _this = this
      this.$refs.addform.validate(valid => {
        if (valid) {
          let data = {
            configureName: this.form.name,
            enterpriseId: this.stationDetailInfo.companyId,
            enterpriseName: this.stationDetailInfo.companyName,
            stationId: this.stationDetailInfo.value,
            stationName: this.stationDetailInfo.label,
            userId: this.userId,
            // userName: this.user.name
          }
          scadaApi.buildSave(data)
            .then(res => {
              console.log(res)
              _this.$message.success('创建成功')
              _this.$parent.$refs.openFile.loadList()
              _this.visible = false
              // _this.$parent.fileData = res.data
              // localStorage.setItem('fileObjZL', JSON.stringify(res.data))
              // _this.closeOpen()
              // if (this.showBtn) {
              //   _this.$parent.$refs.openFile.closeOpen()
              // }
            })
            .catch(() => {})
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
:deep() {
  .el-form-item__label {
    color: #606266 !important;
  }
  .el-input__inner {
    background-color: #fff !important;
    border: 1px solid #dcdfe6 !important;
    color: #606266 !important;
  }
}
.formbox {
  margin: 0 15px;
  form {
    padding-bottom: 15px;
  }
  .dialog-footer {
    text-align: right;
  }
  .closeBtn {
    color: #b6b6b6;
    margin-right: 10px;
  }
}
</style>
